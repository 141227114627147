import { toggleHamburger } from "./toggleHamburger";
import { initializeViewport } from "./initializeViewport";
toggleHamburger("js-hamburger", "js-spmenu", 1024);
initializeViewport();

document.addEventListener("DOMContentLoaded", function () {
  const scrollToTopButton = document.querySelector(".js-button-pagetop");
  if (scrollToTopButton) {
    scrollToTopButton.addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  }
});

gsap.registerPlugin(ScrollTrigger);
